import { Theme, createStyles } from "@material-ui/core";

export const muiProgressStyles = (theme: Theme) =>
  createStyles({
    root: {
      "width": "741px",
      "position": "absolute",
      "top": "9vh",
      "left": "calc((100% - 741px)/2)",
      [theme.breakpoints.down("sm")]: {
        top: "11vh",
        left: "7vw",
        width: "calc(100% - 14vw)",
      },
      "& .MuiLinearProgress-barColorPrimary": {
        backgroundColor: "#FFE038",
      },
      "& .MuiLinearProgress-colorPrimary": {
        backgroundColor: "#9EB1F7",
      },
      "& p": {
        margin: "0 0 8px",
        color: "white",
        textTransform: "uppercase",
        display: "flex",
        justifyContent: "space-between",
        fontSize: "13px",
        [theme.breakpoints.down("sm")]: {
          display: "none",
        },
      },
    },
  });
