import { createStyles } from "@material-ui/core/styles";
import { textInputBackground } from "./theme";

export const styleFormControlLabel = () =>
  createStyles({
    root: {
      alignItems: "start",
      paddingBottom: "1em",
    },
  });

export const styleTextField = () =>
  createStyles({
    disabled: {},
    textField: {
      "background": textInputBackground,
      "&:hover:not($disabled)": {
        background: textInputBackground,
      },
      "&:focus:not($disabled)": {
        background: textInputBackground,
      },
      "&:autofill": {
        background: textInputBackground,
      },
      "&&&:before": {
        borderBottom: "none",
      },
    },
  });
