// extracted by mini-css-extract-plugin
export var h1Typography = "onboard-layout-module--h1-typography--28Bcx";
export var h2Typography = "onboard-layout-module--h2-typography--10ZoI";
export var h3Typography = "onboard-layout-module--h3-typography--25WzX";
export var h4Typography = "onboard-layout-module--h4-typography--1Z0pw";
export var h5Typography = "onboard-layout-module--h5-typography--25130";
export var h6Typography = "onboard-layout-module--h6-typography--20um0";
export var pTypography = "onboard-layout-module--p-typography--10l2y";
export var info = "onboard-layout-module--info--165Np";
export var buttonTypography = "onboard-layout-module--button-typography--2hvot";
export var formLabelTypography = "onboard-layout-module--form-label-typography--7BLqa";
export var menuTabTypography = "onboard-layout-module--menu-tab-typography--2R8ka";
export var linkStyle = "onboard-layout-module--link-style--3QUA-";
export var postTypography = "onboard-layout-module--post-typography--1rRtn";
export var content = "onboard-layout-module--content--3NtN1";
export var componentsPanelSm = "onboard-layout-module--components-panel-sm--yvdFE";
export var componentsPanelMd = "onboard-layout-module--components-panel-md--2I8Re";
export var blueBackground = "onboard-layout-module--blue-background--3SeIg";
export var logoSection = "onboard-layout-module--logo-section--1Li-U";
export var logoSectionWithProgress = "onboard-layout-module--logo-section-with-progress--3xWVQ";