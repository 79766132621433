import { LinearProgress, makeStyles } from "@material-ui/core";
import { FC } from "react";
import { useSelector } from "react-redux";
import { selectOnboardingProgress } from "../../redux/selectors/onboardingSelectors";
import { muiProgressStyles } from "./muiStyles";

const useStyles = makeStyles(muiProgressStyles);

export const Progress: FC = () => {
  const classes = useStyles();
  const progress = useSelector(selectOnboardingProgress);

  if (progress == null) {
    return null;
  }
  const value = (progress.currentStep / progress.steps) * 100;

  return (
    <div className={classes.root}>
      <p>
        <span>Progress</span>
        <span>{`Step ${progress.currentStep} of ${progress.steps}`}</span>
      </p>
      <LinearProgress variant="determinate" value={value} />
    </div>
  );
};
