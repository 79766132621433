import * as styles from "../components/Login/login.module.scss";
import Login from "../components/Login";
import OnboardLayout from "../components/Layout/OnboardLayout";
import React from "react";
import { preventBaseTenantExposure } from "../utils";
import { theme } from "../styles/mui/theme";
import { ThemeProvider } from "@material-ui/core/styles";
import AppSnackbarProvider from "../components/Snackbar/SnackbarProvider";
import Notifier from "../components/Snackbar/Notifier";

const LoginPage = () => {
  preventBaseTenantExposure();

  return (
    <ThemeProvider theme={theme}>
      <AppSnackbarProvider maxSnackbars={1}>
        <Notifier />
        <OnboardLayout>
          <h3 className={styles.headerPosition}>Welcome back!</h3>
          <Login />
        </OnboardLayout>
      </AppSnackbarProvider>
    </ThemeProvider>
  );
};

export default LoginPage;
