import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  FormControlLabel,
  TextField,
  makeStyles,
} from "@material-ui/core";
import { Link, navigate } from "gatsby";
import React, { FC } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { LoginData, login } from "../../redux/actions/authActions";
import {
  styleFormControlLabel,
  styleTextField,
} from "../../styles/mui/styleFormControlLabel";
import { isLoggedIn, loginRedirectTo } from "../../utils/auth";
import * as styles from "./login.module.scss";
import { schema } from "./schemas";

const useStyles = makeStyles(styleFormControlLabel);
const useTextFieldStyles = makeStyles(styleTextField);

const Login: FC = () => {
  const classes = useStyles();
  const textFieldClasses = useTextFieldStyles();

  if (isLoggedIn()) {
    void navigate(loginRedirectTo);
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginData>({
    mode: "onTouched",
    resolver: yupResolver(schema),
  });

  const dispatch = useDispatch();
  const onSubmit = handleSubmit((data): void => {
    dispatch(login(data));
  });

  return (
    <form onSubmit={onSubmit} className={styles.loginForm}>
      <FormControlLabel
        control={
          <TextField
            {...register("email")}
            type="text"
            error={!!errors.email}
            helperText={errors?.email?.message}
            variant="filled"
            InputProps={{ className: textFieldClasses.textField }}
            fullWidth
          />
        }
        classes={classes}
        labelPlacement="top"
        label="EMAIL"
      />
      <FormControlLabel
        control={
          <TextField
            {...register("password")}
            type="password"
            error={!!errors.password}
            helperText={errors?.password?.message}
            variant="filled"
            InputProps={{ className: textFieldClasses.textField }}
            fullWidth
          />
        }
        classes={classes}
        labelPlacement="top"
        label="PASSWORD"
      />
      <Link
        className={`${styles.description} ${styles.smallFont} ${styles.noHrefA}`}
        to="/resetPassword"
      >
        Forgot password? First Time User?
      </Link>
      <Button
        className={styles.button}
        type="submit"
        variant="contained"
        color="primary"
      >
        Log in
      </Button>
    </form>
  );
};

export default Login;
