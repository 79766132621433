import clsx from "clsx";
import { ReactNode } from "react";
import backgroundImage from "../../../images/motko-background.png";
import MotkoLogo from "../../../images/motko-logo.svg";
import { Progress } from "../../Progress";
import * as styles from "./onboard-layout.module.scss";

interface Props {
  children: ReactNode;
  size?: "sm" | "md";
  progress?: boolean;
}

const OnboardLayout = ({ children, size = "sm", progress = false }: Props) => {
  return (
    <div className={styles.content}>
      <div
        style={{ backgroundImage: `url(${backgroundImage as string})` }}
        className={styles.blueBackground}
      >
        <div
          className={clsx({
            [styles.logoSection]: !progress,
            [styles.logoSectionWithProgress]: progress,
          })}
        >
          <MotkoLogo />
        </div>

        {progress ? <Progress /> : null}
      </div>
      <div
        className={clsx({
          [styles.componentsPanelSm]: size === "sm",
          [styles.componentsPanelMd]: size === "md",
        })}
      >
        {children}
      </div>
    </div>
  );
};

export default OnboardLayout;
