// extracted by mini-css-extract-plugin
export var h1Typography = "login-module--h1-typography--VB31d";
export var h2Typography = "login-module--h2-typography--2lcrr";
export var h3Typography = "login-module--h3-typography--2w3VP";
export var h4Typography = "login-module--h4-typography--2_UWQ";
export var h5Typography = "login-module--h5-typography--3Cnq9";
export var h6Typography = "login-module--h6-typography--1fc8r";
export var pTypography = "login-module--p-typography--qfXvv";
export var info = "login-module--info--32Qv9";
export var buttonTypography = "login-module--button-typography--fdjdJ";
export var formLabelTypography = "login-module--form-label-typography--32fXj";
export var menuTabTypography = "login-module--menu-tab-typography--3UiBt";
export var linkStyle = "login-module--link-style--dNVQ9";
export var postTypography = "login-module--post-typography--3pdw1";
export var loginForm = "login-module--login-form--3qRrM";
export var headerPosition = "login-module--header-position--oV6Gm";
export var description = "login-module--description--asNw5";
export var smallFont = "login-module--small-font--1bXbM";
export var noHrefA = "login-module--no-href-a--3Fadl";
export var button = "login-module--button--XpnVn";